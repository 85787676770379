// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import Footer from "../../components/Footer";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";

import { Icon, IconButton, LinearProgress, Menu } from "@mui/material";
import APIError from "components/ApiError";
import ReportsBarChart from "components/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "components/Charts/LineCharts/ReportsLineChart";
import dateFormatter from "components/Formatter/DateFormatter";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import NotificationItem from "components/NotificationItem";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import analyticAPIService from "services/analyticAPI-service";
import ComplexStatisticsCard from "../../components/Cards/StatisticsCards/ComplexStatisticsCard";

function Dashboard() {
  const userSelector = useSelector((state) => state.auth);
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);

  const [activeUnpaidAmount, setActiveUnpaidAmount] = useState("");
  const [inactiveUnpaidAmount, setInActiveUnpaidAmount] = useState("");
  const [activeStudentCount, setActiveStudentCount] = useState("");
  const [inactiveStudentCount, setInactiveStudentCount] = useState("");
  const [prospectiveStudentCount, setProspectiveStudentCount] = useState("");
  const [totalPrepaidAmount, setTotalPrepaidAmount] = useState("");
  const [totalUnpaidAmount, setTotalUnpaidAmount] = useState("");

  //Snack Bar
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  // Dashboard Graph
  const fitlerAttendanceDays = ["1W", "2W", "1M", "2M"];
  const fitlerAmountDays = ["1W", "2W", "3W", "1M"];
  const [openAttendanceMenu, setOpenAttendanceMenu] = useState(false);
  const [openAmountMenu, setOpenAmountMenu] = useState(false);

  const [attendanceProcessing, setAttendanceProcessing] = useState(true);
  const [attendanceDashBoardRange, setAttendanceDashBoardRange] = useState(
    fitlerAttendanceDays.at(0)
  );
  const [attendanceStatsData, setAttendanceStatsData] = useState({});

  const [paidProcessing, setPaidProcessing] = useState(true);
  const [paidAmountDashBoardRange, setPaidAmountDashBoardRange] = useState(
    fitlerAmountDays.at(0)
  );
  const [paidAmoutStatsData, setPaidAmountStatsData] = useState({});

  useEffect(() => {
    fetchDashboardStudentsData();
    fetchAttendanceData();
    fetchPaidAmountData();
  }, []);

  useEffect(() => {
    fetchAttendanceData();
  }, [attendanceDashBoardRange]);

  useEffect(() => {
    fetchPaidAmountData();
  }, [paidAmountDashBoardRange]);

  const fetchAttendanceData = async () => {
    setAttendanceProcessing(true);

    try {
      let todaysDate = new Date()
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-");
      let startDate = getStartDate(attendanceDashBoardRange);

      const dashboardStudentData =
        await analyticAPIService.getAttendanceStatistics(startDate, todaysDate);

      // Now process data
      if (dashboardStudentData?.data?.daily_stats.length > 0) {
        let labels = [];
        let attendaceCount = [];
        dashboardStudentData?.data?.daily_stats.forEach((data) => {
          labels.push(dateFormatter(data.attendance_date));
          attendaceCount.push(data.count);
        });

        setAttendanceStatsData({
          labels: labels,
          datasets: { label: "Count", data: attendaceCount },
        });
        setAttendanceProcessing(false);
      } else {
        setAttendanceStatsData({});
        setAttendanceProcessing(false);
      }
    } catch (error) {
      setAttendanceProcessing(false);
    }
  };

  const fetchPaidAmountData = async () => {
    setPaidProcessing(true);

    try {
      let todaysDate = new Date()
        .toLocaleDateString("en-GB")
        .split("/")
        .reverse()
        .join("-");
      let startDate = getStartDate(paidAmountDashBoardRange);

      const paidAmountResponse =
        await analyticAPIService.getPaidAmountStatistics(startDate, todaysDate);

      // Now process data
      if (paidAmountResponse?.data?.daily_stats.length > 0) {
        let labels = [];
        let paidAmountSum = [];

        paidAmountResponse?.data?.daily_stats.forEach((data) => {
          labels.push(dateFormatter(data.date));
          paidAmountSum.push(data.sum);
        });

        setPaidAmountStatsData({
          labels: labels,
          datasets: { label: "Total Revenue", data: paidAmountSum },
        });
        setPaidProcessing(false);
      } else {
        setPaidAmountStatsData({});
        setPaidProcessing(false);
      }
    } catch (error) {
      setPaidProcessing(false);
    }
  };

  const getStartDate = (filter) => {
    const currentDate = new Date();

    if (filter.endsWith("W")) {
      const weeks = parseInt(filter.slice(0, -1), 10);
      currentDate.setDate(currentDate.getDate() - weeks * 7);
    } else if (filter.endsWith("M")) {
      const months = parseInt(filter.slice(0, -1), 10);
      currentDate.setMonth(currentDate.getMonth() - months);
    } else {
      throw new Error("Invalid filter format");
    }

    return currentDate
      .toLocaleDateString("en-GB")
      .split("/")
      .reverse()
      .join("-"); // Get YYYY-MM-DD format
  };

  const fetchDashboardStudentsData = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const dashboardStudentData =
        await analyticAPIService.getStudentStatistics();

      setActiveUnpaidAmount(
        dashboardStudentData.data?.active_students_unpaid_amount
      );
      setInActiveUnpaidAmount(
        dashboardStudentData.data?.inactive_students_unpaid_amount
      );

      dashboardStudentData.data?.status_count.forEach((count) => {
        switch (count.status) {
          case "Active":
            setActiveStudentCount(count.count);
            break;
          case "Inactive":
            setInactiveStudentCount(count.count);
            break;
          case "Prospective":
            setProspectiveStudentCount(count.count);
            break;
        }
      });

      setTotalPrepaidAmount(dashboardStudentData.data?.total_prepaid_amount);
      setTotalUnpaidAmount(dashboardStudentData.data?.total_unpaid_amount);

      setProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const getRangeDescription = (range) => {
    switch (range) {
      case "1W":
        return "Last 1 week";
      case "2W":
        return "Last 2 weeks";
      case "3W":
        return "Last 3 weeks";
      case "1M":
        return "Last 1 month";
      case "2M":
        return "Last 2 months";
      default:
        return "Last 1 week";
    }
  };

  const handleOpenAttendanceMenu = (event) => {
    setOpenAttendanceMenu(event.currentTarget);
  };

  const handleOpenAmountMenu = (event) => {
    setOpenAmountMenu(event.currentTarget);
  };

  const handleCloseAttendanceMenu = () => setOpenAttendanceMenu(false);
  const handleCloseAmountMenu = () => setOpenAmountMenu(false);

  const handleAttendanceFilterChange = (event) => {
    setAttendanceDashBoardRange(event);
    setOpenAttendanceMenu(false);
  };

  const handleAmountFilterChange = (event) => {
    setPaidAmountDashBoardRange(event);
    setOpenAmountMenu(false);
  };

  const renderAttendanceMenu = (
    <Menu
      anchorEl={openAttendanceMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openAttendanceMenu)}
      onClose={handleCloseAttendanceMenu}
      sx={{ mt: 2 }}
    >
      {fitlerAttendanceDays.map((day) => (
        <NotificationItem
          key={day}
          icon={<Icon>schedule</Icon>}
          title={getRangeDescription(day)}
          onClick={() => handleAttendanceFilterChange(day)}
        />
      ))}
    </Menu>
  );

  const renderAmountMenu = (
    <Menu
      anchorEl={openAmountMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openAmountMenu)}
      onClose={handleCloseAmountMenu}
      sx={{ mt: 2 }}
    >
      {fitlerAmountDays.map((day) => (
        <NotificationItem
          key={day}
          icon={<Icon>schedule</Icon>}
          title={getRangeDescription(day)}
          onClick={() => handleAmountFilterChange(day)}
        />
      ))}
    </Menu>
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {processing && (
        <MDBox pt={2} px={2}>
          <LinearProgress
            color="info"
            variant="indeterminate"
            sx={{ overflow: "hidden" }}
          />
        </MDBox>
      )}

      {!processing && (
        <MDBox py={3}>
          {/* Cards Data */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="person_off"
                  title="Inactive Students"
                  count={inactiveStudentCount}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="school"
                  title="Active Students"
                  count={activeStudentCount}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Prospective Students"
                  count={prospectiveStudentCount}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="secondary"
                  icon="paid"
                  title="Total Unpaid Amount"
                  count={`$ ${totalUnpaidAmount}`}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="warning"
                  icon="paid"
                  title="Active Students Unpaid Amount"
                  count={`$ ${activeUnpaidAmount}`}
                />
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="error"
                  icon="paid"
                  title="Inactive Students Unpaid Amount"
                  count={`$ ${inactiveUnpaidAmount}`}
                />
              </MDBox>
            </Grid>
          </Grid>
          {/* Graph Data */}
          <Grid container spacing={3} mt={5} rowGap={8}>
            <Grid item xs={12} md={6}>
              <MDBox mb={3} textAlign="right">
                <IconButton
                  size="small"
                  variant="contained"
                  onClick={handleOpenAttendanceMenu}
                >
                  <MDTypography variant="h7" color="secondary" mr={1}>
                    {getRangeDescription(attendanceDashBoardRange)}
                  </MDTypography>

                  <Icon>filter_list</Icon>
                </IconButton>
              </MDBox>

              {attendanceProcessing && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}
              {!attendanceProcessing && (
                <ReportsBarChart
                  color="info"
                  title="Attendance Count"
                  description="Students attendance count"
                  date={getRangeDescription(attendanceDashBoardRange)}
                  chart={attendanceStatsData}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <MDBox mb={3} textAlign="right">
                <IconButton
                  size="small"
                  variant="contained"
                  id="paidMenu"
                  onClick={handleOpenAmountMenu}
                >
                  <MDTypography variant="h7" color="secondary" mr={1}>
                    {getRangeDescription(paidAmountDashBoardRange)}
                  </MDTypography>

                  <Icon>filter_list</Icon>
                </IconButton>
              </MDBox>

              {paidProcessing && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}

              {!paidProcessing && (
                <ReportsLineChart
                  color="success"
                  title="Revenue"
                  description="Paid amount in $"
                  date={getRangeDescription(paidAmountDashBoardRange)}
                  chart={paidAmoutStatsData}
                />
              )}
            </Grid>
          </Grid>
        </MDBox>
      )}
      {/* Api Error */}
      {!processing && apiError && <APIError />}
      {renderSnackBar}
      {renderAttendanceMenu}
      {renderAmountMenu}
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
