// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import LinearProgress from "@mui/material/LinearProgress";
import Footer from "../../components/Footer";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";

import {
  CircularProgress,
  Icon,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import APIError from "components/ApiError";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import NotificationItem from "components/NotificationItem";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  default as StudentApiService,
  default as studentAPIService,
} from "services/studentAPI-service";
import colors from "../../assets/theme/base/colors";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";
import AddPaymentDialog from "./paymentDetails/addPayment";
import AddAttendanceDialog from "./scheduleDetails/addAttendance";

import csvIcon from "assets/icons/csvIcon.svg";
import AddStudentDialog from "./addStudent";

function Students() {
  const userSelector = useSelector((state) => state.auth);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("Active");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [menu, setMenu] = useState(null);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [downloadProcessing, setDownloadProcessing] = useState(false);

  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [selectedStudentName, setSelectedStudentName] = useState("");
  const [addAttendanceDialogOpen, setAddAttendanceDialogOpen] = useState(false);
  const [addPaymentDialogOpen, setAddPaymentDialogOpen] = useState(false);
  const [addStudentDialogOpen, setAddStudentDialogOpen] = useState(false);

  //Snack Bar
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const handleNameSearch = (e) => {
    setPage(0);
    setName(e.target.value);
  };

  const handleOrderByChange = (e) => {
    setPage(0);
    setOrderBy(e.target.value);
  };

  const handleStatusChange = (e) => {
    setPage(0);
    setStatus(e.target.value);
  };

  const columns = ["Name", "Unpaid Amount", "Status", "Actions"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchStudentData();
  }, [name, status, orderBy, page, rowsPerPage]);

  const openActions = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderActionMenu = (
    <Menu
      id="actions-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>
        <NotificationItem icon={<Icon>visibility</Icon>} title="View" />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        <NotificationItem icon={<Icon>delete</Icon>} title="Delete" />
      </MenuItem>
    </Menu>
  );

  const fetchStudentData = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const studentListResponse = await StudentApiService.getStudentsList(
        rowsPerPage,
        page + 1,
        status,
        name,
        orderBy
      );
      setRows(studentListResponse.data.results);
      setTotal(studentListResponse.data.count);
      setProcessing(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const handleAddAttendace = (student) => {
    setSelectedStudentId(student.id);
    setSelectedStudentName(student.first_name + " " + student.last_name);
    setAddAttendanceDialogOpen(true);
  };

  const handleAddPayment = (student) => {
    setSelectedStudentId(student.id);
    setSelectedStudentName(student.first_name + " " + student.last_name);
    setAddPaymentDialogOpen(true);
  };

  const handleAddPaymentClose = (response) => {
    if (response === "Close") {
      setAddPaymentDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Payment record has been added.");
      openSnackBar();
      fetchStudentData();
    }
    setSelectedStudentId("");
    setSelectedStudentName("");
    setAddPaymentDialogOpen(false);
  };

  const handleAddAttendanceClose = (response) => {
    if (response === "Close") {
      setAddAttendanceDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Attendance record has been updated.");
      openSnackBar();
      fetchStudentData();
    }
    setSelectedStudentId("");
    setSelectedStudentName("");
    setAddAttendanceDialogOpen(false);
  };

  const handleAddStudent = () => {
    setAddStudentDialogOpen(true);
  };

  const downloadCSV = async () => {
    setDownloadProcessing(true);
    try {
      const report = await studentAPIService.downloadStudentsListCSV();
      const blob = new Blob([report.data], { type: "text/csv" });

      // Now open save as to save this csv file
      saveAs(blob, "studentsList.csv");
      setDownloadProcessing(false);

      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Students report has been downloaded.");
      openSnackBar();
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to download the Students Report.");
      openSnackBar();
      setDownloadProcessing(false);
    }
  };

  const handleAddStudentDialogClose = (response) => {
    if (response === "Close") {
      setAddStudentDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Student has been added.");
      openSnackBar();
      fetchStudentData();
      setAddStudentDialogOpen(false);
    }
  };

  const renderAddStudentDialog = (
    <AddStudentDialog
      open={addStudentDialogOpen}
      onClose={handleAddStudentDialogClose}
    />
  );

  const renderAddPaymentDialog = (
    <AddPaymentDialog
      studentId={selectedStudentId}
      studentName={selectedStudentName}
      open={addPaymentDialogOpen}
      onClose={handleAddPaymentClose}
    />
  );

  // Render Add Attendance Popup
  const renderAddAttendanceDialog = (
    <AddAttendanceDialog
      studentId={selectedStudentId}
      studentName={selectedStudentName}
      open={addAttendanceDialogOpen}
      onClose={handleAddAttendanceClose}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Search Box */}
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        rowGap={1}
        columnGap={1}
      >
        {/* Input Search */}
        <Grid item md={4}>
          <MDInput
            placeholder="Search by name..."
            value={name}
            size="medium"
            onChange={handleNameSearch}
            sx={{
              width: 250,
              background: "white",
              borderRadius: "6px",
            }}
            type="search"
            InputProps={{
              startAdornment: (
                <Icon sx={{ color: "gray" }} fontSize="small">
                  search
                </Icon>
              ),
            }}
          />
        </Grid>

        <Grid
          item
          md={7}
          display={"flex"}
          justifyContent={"flex-end"}
          columnGap={"20px"}
        >
          {/* Order By  */}
          <Select
            id="orderBySelection"
            value={orderBy}
            displayEmpty
            onChange={handleOrderByChange}
            startAdornment={
              <InputAdornment position="start">
                <Icon color="info">sort</Icon>
              </InputAdornment>
            }
            variant="outlined"
            sx={{
              "& .MuiSelect-select.MuiSelect-select": {
                color: colors.info.main,
                fontWeight: 500,
                height: "2.8rem",
              },
              background: colors.white.main,
            }}
          >
            <MenuItem value={""}>Order By</MenuItem>
            <MenuItem value={"first_name"}>First Name - Ascending</MenuItem>
            <MenuItem value={"last_name"}>Last Name - Ascending</MenuItem>
            <MenuItem value={"unpaid_amount"}>
              Unpaid Amount- Ascending
            </MenuItem>
            <MenuItem value={"-unpaid_amount"}>
              Unpaid Amount- Descending
            </MenuItem>
          </Select>

          {/* Status */}
          <Select
            id="statusSelectLabel"
            value={status}
            displayEmpty
            onChange={handleStatusChange}
            startAdornment={
              <InputAdornment position="start">
                <Icon color="primary">filter_list</Icon>
              </InputAdornment>
            }
            variant="outlined"
            sx={{
              "& .MuiSelect-select.MuiSelect-select": {
                color:
                  status === ""
                    ? colors.info.main
                    : status === "Active"
                    ? colors.success.main
                    : status === "Prospective"
                    ? colors.warning.main
                    : status === "Waitlist"
                    ? colors.info.main
                    : colors.error.main,
                fontWeight: 500,
                height: "2.8rem",
              },
              background: colors.white.main,
            }}
          >
            <MenuItem value={""}>Status</MenuItem>
            <MenuItem value={"Active"}>Active</MenuItem>
            <MenuItem value={"Inactive"}>Inactive</MenuItem>
            <MenuItem value={"Prospective"}>Prospective</MenuItem>
            <MenuItem value={"Waitlist"}>Waitlist</MenuItem>
          </Select>

          {/* Show download button only when admin or superadmin */}
          {Array.of("admin", "superadmin").includes(userSelector.userType) && (
            <MDBox display="flex" justifyContent="right">
              <MDButton
                color="info"
                disabled={downloadProcessing}
                onClick={downloadCSV}
              >
                <img
                  src={csvIcon}
                  name="csvIcons"
                  height={20}
                  width={25}
                  style={{ marginRight: "5px" }}
                />
                Download Students
                {downloadProcessing && (
                  <CircularProgress
                    size={14}
                    sx={{ marginLeft: 1 }}
                    color="warning"
                  />
                )}
              </MDButton>
            </MDBox>
          )}
        </Grid>
      </Grid>

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* Heading */}
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Students
                </MDTypography>
              </MDBox>
              {/* Add Button */}
              {Array.of("superadmin").includes(userSelector.userType) && (
                <MDBox
                  mb={1}
                  mt={1}
                  pr={2}
                  display="flex"
                  justifyContent="end"
                  gap={2}
                  flexWrap="wrap"
                >
                  <MDButton
                    variant="contained"
                    color="dark"
                    type="button"
                    onClick={handleAddStudent}
                  >
                    + Add Student
                  </MDButton>
                </MDBox>
              )}

              {/* Progress Bar */}
              {processing && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}
              {/* Table */}
              {!processing && total > 0 && (
                <MDBox pt={3} px={1} pb={5}>
                  <TableContainer component={Paper}>
                    <Table>
                      {/* Header Columns */}
                      <TableHead>
                        <TableRow>
                          {columns.map((column, i) => (
                            <TableCell key={i}>{column}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {/* Body Contents */}
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell>
                              <Link to={`/students/${row.id}`}>
                                <MDTypography
                                  variant="h7"
                                  fontWeight="bold"
                                  color="info"
                                >
                                  {row.first_name} {row.last_name}
                                </MDTypography>
                              </Link>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                $ {row.unpaid_amount}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography
                                variant="h7"
                                fontWeight="bold"
                                color={
                                  row.status === "Active"
                                    ? "success"
                                    : row.status === "Inactive"
                                    ? "error"
                                    : row.status === "Prospective"
                                    ? "warning"
                                    : "info"
                                }
                              >
                                {row.status}
                              </MDTypography>
                            </TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                columnGap: "20px",
                                rowGap: "20px",
                              }}
                            >
                              <MDButton
                                variant="contained"
                                color="info"
                                size="small"
                                onClick={() => {
                                  handleAddAttendace(row);
                                }}
                              >
                                Attendance
                              </MDButton>
                              <MDButton
                                variant="contained"
                                color="success"
                                size="small"
                                onClick={() => {
                                  handleAddPayment(row);
                                }}
                              >
                                Payment
                              </MDButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton
                    showLastButton
                  />
                </MDBox>
              )}
              {/* No Data Found */}
              {!processing && !apiError && total === 0 && (
                <MDBox pt={3} px={4} pb={5}>
                  <MDTypography variant="h7" color="error">
                    No student found
                  </MDTypography>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {addAttendanceDialogOpen && renderAddAttendanceDialog}
      {addPaymentDialogOpen && renderAddPaymentDialog}
      {addStudentDialogOpen && renderAddStudentDialog}
      {renderSnackBar}
      {/* API Error */}
      {!processing && apiError && <APIError />}
      <Footer />
    </DashboardLayout>
  );
}

export default Students;
